import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-view-table-row-detail',
  templateUrl: './view-table-row-detail.component.html',
  styleUrls: ['./view-table-row-detail.component.scss'],
})
export class ViewTableRowDetailComponent implements OnInit {
@Input() data: any;
@Input() columnData: any;
@Input() PageName: any;
  record: any;
  rows: any[][] = [];
  constructor(private modalController: ModalController,) { }

  ngOnInit() {
    this.rows = this.chunkArray(this.columnData, 4); // Change 4 to 5 if needed
  }

  dismissModal() {
    this.modalController.dismiss();
  }

  chunkArray(data: any[], size: number): any[][] {
    const result = [];
    for (let i = 0; i < data.length; i += size) {
      result.push(data.slice(i, i + size));
    }
    return result;
  }

  trackByIndex(index: number): number {
    return index;
  }

}
