import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthPage } from './auth.page';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { OtpComponent } from './otp/otp.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { InnerpageGuard } from 'src/app/guards/innerpage.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: AuthPage,
    canActivate:[InnerpageGuard]
  },
  {
    path: 'change-password',
    component: ChangePasswordComponent,
    canActivate:[InnerpageGuard]
  },
  {
    path: 'otp',
    component: OtpComponent
  },
  {
    path: 'forgotten-password',
    component: ForgotPasswordComponent,
    canActivate:[InnerpageGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthPageRoutingModule {}
