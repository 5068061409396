/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable guard-for-in */
/* eslint-disable no-trailing-spaces */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-inferrable-types */
/* eslint-disable no-var */
/* eslint-disable prefer-const */
import { Component, Inject, Input, OnInit, OnDestroy, ChangeDetectorRef, HostListener } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Role } from 'src/app/models/role.model';
import { ApiService } from 'src/app/services/api.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { User, UserWithoutToken } from 'src/app/models/user.model';
import { PersistedValueEnum } from 'src/app/models/persisted-value-enum';
import { Subscription } from 'rxjs';
import { UtilityService } from 'src/app/services/utility.service';
import { Email } from 'src/app/models/email.model';
import { Client } from 'src/app/models/client.model';
import { environment } from 'src/environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogUtility } from '@syncfusion/ej2-angular-popups';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
})
export class UserComponent implements OnInit, OnDestroy {

  formTitle: string;
  isNewUser: boolean;
  btnPass = 'Reset Password';
  currentUser: User;
  userForm: FormGroup;
  passwordForm: FormGroup;
  roles: Role[] = [];
  userList: User[] = [];
  isDuplicate: boolean = false;
  canDelete: boolean = true;
  isSaving: boolean = false;
  changingPass: boolean = false;
  selfService: boolean = false;
  isPasswordError: boolean = false;
  errorMessage: string;
  successMessage: string;
  isSuccess: boolean = false;
  passwordMatch:boolean = false
  editableForm:boolean=true
  public userSub: Subscription;
  public roleSub: Subscription;
  public authSub: Subscription;
  public emailSub: Subscription;
  public isDeleting = false;
  passwordStrengthMessage: string = '';
  suggestedPasswords: string[] = [];
  showPasswordSuggestions = false;
  hide:boolean = false
  constructor(
    public apiService: ApiService,
    private utilityService: UtilityService,
    private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<UserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {users: User[]; id: number; client: Client; sService: boolean},
  private cdr:ChangeDetectorRef) { }

  ngOnInit() {
    this.selfService = this.data?.sService;
    this.userList = this.data?.users;
    this.formTitle = 'New User';
    this.isNewUser = true;

    this.userForm = new FormGroup({
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      address: new FormControl(''),
      password: new FormControl('', [Validators.required,Validators.minLength(6)]),
      email: new FormControl('', [Validators.required, Validators.email]),
      role: new FormControl('', [Validators.required]),
      phone: new FormControl('', [Validators.required])
    });

    if (this.data?.users === null){
      this.getUsers();
    }
    else{
      this.userList = this.data?.users;
      this.getRoles();
      this.setForm();
    }
    if (!this.isNewUser){
      if (this.currentUser?.userName === localStorage.getItem(PersistedValueEnum?.userName)){
        this.canDelete = false;
      }
    }
    this.generateStrongPasswords();
  }
  toggleEditableForm(): void {
    this.editableForm = true;
  }
  isDisabled(): boolean {
    return this.currentUser ? this.editableForm : false;
  }

  updateDisabledState(): void {
    const shouldDisable = !!this.editableForm; // Convert to boolean
    this.toggleFormControls(this.userForm, shouldDisable, [
        'role'
    ]);

    // Optional: Trigger Angular's change detection
    this.cdr.detectChanges();
}
private toggleFormControls(formGroup: FormGroup, disable: boolean, controlNames: string[]): void {
  controlNames.forEach(controlName => {
      const control = formGroup.get(controlName);
      if (control) {
          disable ? control?.disable({ emitEvent: false }) : control?.enable({ emitEvent: false });
      }
  });
}

  public setForm(): void{
    console.log(this.data)
    if (this.data?.id > 0){
      this.formTitle = this.selfService ? 'My Profile' : 'Edit User';
      this.btnPass = this.selfService ? 'Change Password' : 'Reset Password';
      this.isNewUser = false;
      this.currentUser = this.userList.find(f => f.userId == this.data?.id);
      this.userForm = new FormGroup({
        firstName: new FormControl(this.currentUser?.firstName, [Validators.required]),
        lastName: new FormControl(this.currentUser?.lastName, [Validators.required]),
        password: new FormControl(''),
        address: new FormControl(this.currentUser?.address),
        email: new FormControl(this.currentUser?.email, [Validators.required, Validators.email]),
        role: new FormControl(this.currentUser?.roleId, [Validators.required]),
        phone: new FormControl(this.currentUser?.phone, [Validators.required])
      });
      this.updateDisabledState()
    }
  }

  strongPasswordValidator() {
    const password = this.userForm.value.password|| '';
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumber = /\d/.test(password);
    const hasSpecialChar = /[@$!%*?&]/.test(password);

    if (!hasUpperCase || !hasLowerCase || !hasNumber || !hasSpecialChar || password.length < 8) {
      return  true;
    }
    return false;
  }
  generateStrongPasswords() {
    const uppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const lowercase = 'abcdefghijklmnopqrstuvwxyz';
    const numbers = '0123456789';
    const specialChars = '@$!%*?&';
    const allChars = uppercase + lowercase + numbers + specialChars;

    this.suggestedPasswords = Array.from({ length: 3 }, () => {
      let password = '';

      // Ensure at least one character from each category
      password += uppercase[Math.floor(Math.random() * uppercase.length)];
      password += lowercase[Math.floor(Math.random() * lowercase.length)];
      password += numbers[Math.floor(Math.random() * numbers.length)];
      password += specialChars[Math.floor(Math.random() * specialChars.length)];

      // Fill remaining characters randomly
      for (let i = 4; i < 10; i++) {
        password += allChars[Math.floor(Math.random() * allChars.length)];
      }

      // Shuffle the password to avoid predictable patterns
      return password.split('').sort(() => 0.5 - Math.random()).join('');
    });
  }

  confirmPasswordForGeneratedStringPassword:string=''
  fillPassword(password: string) {
    this.userForm.controls['password'].setValue(password);
    this.confirmPasswordForGeneratedStringPassword = password;
    this.passwordMatch = true
    this.showPasswordSuggestions = false; // Hide suggestions after selection
  }

  onFocus() {
    this.showPasswordSuggestions = true;
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: Event) {
    if (!(event.target as HTMLElement).closest('.password-container')) {
      this.showPasswordSuggestions = false;
    }
  }


  passwordsMatchValidator(event: any) {
   if(this.userForm.value.password !== event.target.value){
    this.passwordMatch = false
    return
   }
   this.passwordMatch = true
  }

  public submit(): void{
    if(this.passwordMatch === false && this.userForm.value.password !== '' && this.strongPasswordValidator()){
      return
    }
    this.isSaving = true;
    this.isDuplicate = false;
    const email: string = this.userForm.value.email;
    if (this.userList !== null && this.userList !== undefined && this.userList.length > 0){
      for (let u of this.userList){
        if (u.email.toUpperCase() === email.toUpperCase() || u.userName.toUpperCase() === email.toUpperCase())
        {
          if (this.currentUser === undefined){
          this.isDuplicate = true;
          break;
          }
          else{
            if(this.currentUser.userId != u.userId){
              this.isDuplicate = true;
              break;
            }
          }
        }
      }
    }
    if(!this.isDuplicate){
      if( this.data.id > 0){

        this.insertUser(this.data.id);
      }else{
        this.insertUser(0);
      }
    }
    else{
      this.isSaving = false;
    }
  }

  public getRoles(): void{
    try{
      let data ={
        wroles: 'active'
      };
      this.roleSub = this.apiService.getRoles(data).subscribe((res: Role[]) => {
        this.roles = res;
      },(err)=>{
        this.apiService.openErrorSnackBar(err?.error?.error ? err?.error?.error : 'Please contact support team', null)
      }),(err)=>{
        this.apiService.openErrorSnackBar(err?.error?.error ? err?.error?.error : 'Please contact support team', null)
      };
    }  catch(err){
      this.apiService.openErrorSnackBar(err?.error?.error ? err?.error?.error : 'Please contact support team', null)
    }

  }

  public insertUser(tempid: number): void{
    try{
      var obj: User;
      obj = {
        firstName: this.userForm.value.firstName,
        // userId: null,
        lastName: this.userForm.value.lastName,
        address: this.userForm.value.address,
        userName: this.userForm.value.email,
        email: this.userForm.value.email,
        phone: this.userForm.value.phone,
        roleId: this.userForm.value.role,
        roleName: 'none',
        token: 'none',
        token_exp: null,
        isActive: true,
        clientID: this.data?.client?.clientID
      };
      if(this.userForm.value.password){
        obj['password'] = this.userForm.value.password
      }
    var newPass: string = this.makeRandomPassword();
    if (tempid == 0){
      let data ={
        user: obj,
        randomPass: newPass
      };
      this.userSub = this.apiService.addUser(data).subscribe((res: User) => {
        if(res !== null){
          this.apiService.openSnackBar(`Successfully Added`);
            this.isSaving = false;
            this.close(true);
          // var emailObj: Email;
          // emailObj ={
          //   email : obj.email,
          //   subject: 'New Account',
          //   variables: '{"firstname":"'+obj.firstName+'", "username":"'+obj.userName+'", "password":"'+newPass+'", "url":"'+ this.data.client.subdomain + '.'+environment.siteURL+'"}',
          //   template: 'welcome'
          // };

          // this.emailSub =  this.utilityService.sendEmail(emailObj).subscribe((result: boolean) => {
          //   //console.log(result);
          //   this.isSaving = false;
          //   this.close(true);
          // });
        }
      },(err:any)=>{
        this.isSaving = false
         this.confirmPasswordForGeneratedStringPassword = ''
        this.apiService.openErrorSnackBar(err?.error?.error ? err?.error?.error : 'Please contact support team', null)

      }),(err:any)=>{
        this.isSaving = false
         this.confirmPasswordForGeneratedStringPassword = ''
        this.apiService.openErrorSnackBar(err?.error?.error ? err?.error?.error : 'Please contact support team', null)
      };
    }
    else{
      obj['id']= tempid
      !this.userForm.value.password ? delete obj.password : null
      let data ={
        user: obj,
        wtd: 'updateUser'
      };
      this.userSub = this.apiService.updateUser(data).subscribe((res: User) => {
        if(res !== null){
          this.apiService.openSnackBar(`Successfully Updated`);
          this.isSaving = false;
          this.close(true);
          }
      },(err:any)=>{
        this.isSaving = false
         this.confirmPasswordForGeneratedStringPassword = ''
         this.apiService.openErrorSnackBar(err?.error?.error ? err?.error?.error : 'Please contact support team', null)

      }),(err:any)=>{
        this.isSaving = false
         this.confirmPasswordForGeneratedStringPassword = ''
         this.apiService.openErrorSnackBar(err?.error?.error ? err?.error?.error : 'Please contact support team', null)
      };
    }

    } catch(err){
      this.apiService.openErrorSnackBar(err?.error?.error ? err?.error?.error : 'Please contact support team', null)
    }

  }

  public getUsers(): void{
    try{
      let data ={
        userId: 'all',
        clientID: this.data?.client?.clientID
      };
      this.userSub = this.apiService.getUser(data).subscribe((res: User[]) => {
        this.userList = res;
        this.setForm();
      },(err)=>{
        this.apiService.openErrorSnackBar(err?.error?.error ? err?.error?.error : 'Please contact support team', null)
      }),(err)=>{
        this.apiService.openErrorSnackBar(err?.error?.error ? err?.error?.error : 'Please contact support team', null)
      };
    } catch(err){
      this.apiService.openErrorSnackBar(err?.error?.error ? err?.error?.error : 'Please contact support team', null)
    }

  }

  public close(added: boolean): void{
    this.dialogRef.close(added);
  }

  public makeRandomPassword(): string {
    let possible = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890&%$#@!?";
    const lengthOfCode = 6;
    let text = "";
    for (let i = 0; i < lengthOfCode; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
      return text;
  }

  public deleteUser(): void{
    this.isDeleting = true
    try{
      let data ={
        userId: this.currentUser.userId
      };
      this.userSub = this.apiService.deleteUser(data).subscribe((res: any) =>{
        this.apiService.openSnackBar(`Successfully Deleted`);
        this.close(true);
        this.isDeleting = false
      },(err)=>{
        this.isDeleting = false
        this.apiService.openErrorSnackBar(err?.error?.error ? err?.error?.error : 'Please contact support team', null)
      }),(err)=>{
        this.isDeleting = false
        this.apiService.openErrorSnackBar(err?.error?.error ? err?.error?.error : 'Please contact support team', null)
      };
    } catch(err){
      this.isDeleting = false
      this.apiService.openErrorSnackBar(err?.error?.error ? err?.error?.error : 'Please contact support team', null)
    }

  }

  public changePass(): void{
    if (this.selfService){
      this.passwordForm = new FormGroup({
        password: new FormControl('', [Validators.required]),
        newPassword: new FormControl('', [Validators.required, Validators.minLength(6)]),
        confirmPassword: new FormControl('', [Validators.required])
      });
      this.changingPass = true;
    }
    else{
     this.passwordReset();
    }
  }

  public passwordReset(): void{
    try{
      this.isSaving = true;
      const newPass: string = this.makeRandomPassword();
      //console.log(newPAss);
      this.currentUser.token = this.selfService ? this.passwordForm.value.newPassword : newPass;
      let data ={
        user: this.currentUser.userId,
        wtd: 'password',
        pass: this.currentUser.token
      };
      this.userSub = this.apiService.updateUser(data).subscribe((res: User) => {
        if(res !== null){

          var emailObj: Email;
            emailObj ={
              email : this.currentUser.email,
              subject: 'Password reset',
              variables: '{"firstname":"'+this.currentUser.firstName+'", "username":"'+this.currentUser.userName+'","password":"'+newPass+'", "url":"'+ this.data.client.subdomain + '.'+environment.siteURL+'"}',
              template: 'passreset'
            };

            this.emailSub =  this.utilityService.sendEmail(emailObj).subscribe((result: boolean) => {
             console.log(result);
              this.isSaving = false;
              this.close(true);
            });



          this.isSaving = false;
          this.isPasswordError = false;
          //this.passwordForm.reset();
          this.isSuccess = true;
          this.changingPass = false;
          this.successMessage = 'Password changed!';
          }
          else{
            this.isPasswordError = true;
            this.errorMessage = 'Cannot change password. Please contact the system administrator';
          }
      },(err)=>{
        this.apiService.openErrorSnackBar(err?.error?.error ? err?.error?.error : 'Please contact support team', null)
      }),(err)=>{
        this.apiService.openErrorSnackBar(err?.error?.error ? err?.error?.error : 'Please contact support team', null)
      };
    } catch(err){
      this.apiService.openErrorSnackBar(err?.error?.error ? err?.error?.error : 'Please contact support team', null)
    }

  }

  public verifyPassword(): void{
    try{
      let data ={
        password: this.passwordForm.value.password,
        userName: this.currentUser.userName,
        clientID: this.currentUser.clientID
      };
      this.authSub = this.apiService.authenticateUser(data).subscribe((res: UserWithoutToken | string) =>{
        if (res === null || res === 'Invalid Password'){
          this.isPasswordError = true;
          this.errorMessage = 'Invalid Current Password';
        }
        else{
          this.passwordReset();
        }
      },(err)=>{
        this.apiService.openErrorSnackBar(err?.error?.error ? err?.error?.error : 'Please contact support team', null)
      }),(err)=>{
        this.apiService.openErrorSnackBar(err?.error?.error ? err?.error?.error : 'Please contact support team', null)
      };
    } catch(err){
      this.apiService.openErrorSnackBar(err?.error?.error ? err?.error?.error : 'Please contact support team', null)
    }

  }

  public submitPChange(): void{
    this.isPasswordError = false;
    if(this.passwordForm.value.password === this.passwordForm.value.newPassword){
      this.errorMessage = 'Cannot use your current password';
      this.isPasswordError = true;
    }
    else{
      if(this.passwordForm.value.newPassword !== this.passwordForm.value.confirmPassword){
      this.errorMessage = 'New Password and Confirm Password do not match';
      this.isPasswordError = true;
      }
      else{
        this.verifyPassword();
      }
    }



  }

  ngOnDestroy(){
    if (this.userSub !== null && this.userSub !== undefined)
    {
      this.userSub.unsubscribe();
    }

    if (this.roleSub !== null && this.roleSub !== undefined)
    {
      this.roleSub.unsubscribe();
    }

    if (this.authSub !== null && this.authSub !== undefined)
    {
      this.authSub.unsubscribe();
    }
  }

}
