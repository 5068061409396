import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { ApiService } from '../services/api.service';

@Injectable({
  providedIn: 'root'
})
export class InnerpageGuard implements CanActivate {
  constructor(private service: ApiService, private route: Router){

    }
    canActivate(){
      if(this.service.loggedInCheck()){
         return true;
      }else{
        this.route.navigate(['home']);
         return false;
      }
    }

}
