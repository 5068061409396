import { Component, HostListener, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PersistedValueEnum } from 'src/app/models/persisted-value-enum';
import { ApiService } from 'src/app/services/api.service';

// Custom function to check if password is strong
function strongPasswordValidator(minLength: number = 8): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value: string = control.value;

    // Check for minimum length
    if (value && value.length < minLength) {
      return { 'minLength': { requiredLength: minLength, actualLength: value.length } };
    }

    // Check for uppercase letters
    if (!/[A-Z]/.test(value)) {
      return { 'uppercase': true };
    }

    // Check for lowercase letters
    if (!/[a-z]/.test(value)) {
      return { 'lowercase': true };
    }

    // Check for numbers
    if (!/\d/.test(value)) {
      return { 'digit': true };
    }

    // Check for special characters
    if (!/[!@#$%^&*(),.?":{}|<>]/.test(value)) {
      return { 'specialChar': true };
    }

    // All checks pass
    return null;
  };
}


// Password matching validation
function matchPasswordValidator(controlName: string, matchingControlName: string): ValidatorFn {
  return (formGroup: AbstractControl): { [key: string]: any } | null => {
    const passwordControl = formGroup.get(controlName);
    const confirmPasswordControl = formGroup.get(matchingControlName);

    // If controls do not exist, return null (no validation error)
    if (!passwordControl || !confirmPasswordControl) {
      return null;
    }

    // If password and confirmPassword controls' values are different, set error
    if (passwordControl.value !== confirmPasswordControl.value) {
      confirmPasswordControl.setErrors({ 'passwordMismatch': true });
    } else {
      confirmPasswordControl.setErrors(null);
    }

    return null;
  };
}

@Component({
  selector: 'app-chpw',
  templateUrl: './chpw.component.html',
  styleUrls: ['./chpw.component.scss'],
})
export class ChpwComponent implements OnInit {

  changePasswordForm: FormGroup;                                  // Form control for changing password
  token: string;                                                  // Token in query parameter
  errorMessage: string;                                                  // Token in query parameter
  successMessage: string;                                                  // Token in query parameter
  isLoading: boolean;                                                  // Token in query parameter
  isAuthRoute: boolean = false;                                                  // Token in query parameter
  passwordStrengthMessage: string = '';
  suggestedPasswords: string[] = [];
  showPasswordSuggestions = false;
  hide:boolean = false
  constructor(private route: ActivatedRoute, private apiService: ApiService, private router: Router) {
    this.token = null;
    this.isLoading = false;
    this.successMessage = "";
    this.errorMessage = "";
    if (window.location.href.includes("/auth/change-password")) {
      this.isAuthRoute = true;
    }

  }

  navigateToLogin = (): void => {
    // window.location.href = '/#/auth';
    // window.location.reload();
    this.router.navigate(['/auth/login'])
  }

  ngOnInit() {
    this.initializeForms();
    if (this.route.snapshot.queryParamMap.keys.length > 0) {
      const queryParams = this.route.snapshot.queryParamMap;
      this.token = queryParams.get("resetToken");
      this.changePasswordForm.controls.oldPassword.setValue(this.token)
    }
    this.generateStrongPasswords()
  }

  initializeForms = (): void => {
    this.changePasswordForm = new FormGroup({
      oldPassword: new FormControl('', [Validators.required]),
      newPassword: new FormControl('', [Validators.required, strongPasswordValidator(8)]),
      reNewPassword: new FormControl('', [Validators.required]),
    }, {
      validators: matchPasswordValidator("newPassword", "reNewPassword")
    })
  }

  strongPasswordValidator() {
      const password = this.changePasswordForm.value.newPassword|| '';
      const hasUpperCase = /[A-Z]/.test(password);
      const hasLowerCase = /[a-z]/.test(password);
      const hasNumber = /\d/.test(password);
      const hasSpecialChar = /[@$!%*?&]/.test(password);

      if (!hasUpperCase || !hasLowerCase || !hasNumber || !hasSpecialChar || password.length < 8) {
        return  true;
      }
      return false;
    }
    generateStrongPasswords() {
      const uppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
      const lowercase = 'abcdefghijklmnopqrstuvwxyz';
      const numbers = '0123456789';
      const specialChars = '@$!%*?&';
      const allChars = uppercase + lowercase + numbers + specialChars;

      this.suggestedPasswords = Array.from({ length: 3 }, () => {
        let password = '';

        // Ensure at least one character from each category
        password += uppercase[Math.floor(Math.random() * uppercase.length)];
        password += lowercase[Math.floor(Math.random() * lowercase.length)];
        password += numbers[Math.floor(Math.random() * numbers.length)];
        password += specialChars[Math.floor(Math.random() * specialChars.length)];

        // Fill remaining characters randomly
        for (let i = 4; i < 10; i++) {
          password += allChars[Math.floor(Math.random() * allChars.length)];
        }

        // Shuffle the password to avoid predictable patterns
        return password.split('').sort(() => 0.5 - Math.random()).join('');
      });
    }

    confirmPasswordForGeneratedStringPassword:string=''
    fillPassword(password: string) {
      this.changePasswordForm.controls['newPassword'].setValue(password);
      this.changePasswordForm.controls['reNewPassword'].setValue(password);
      this.confirmPasswordForGeneratedStringPassword = password;
      this.showPasswordSuggestions = false; // Hide suggestions after selection
    }

    onFocus() {
      this.showPasswordSuggestions = true;
    }

    @HostListener('document:click', ['$event'])
    onClickOutside(event: Event) {
      if (!(event.target as HTMLElement).closest('.password-container')) {
        this.showPasswordSuggestions = false;
      }
    }


  // handle Form Submit
  handleSubmitPasswordChange = async (): Promise<void> => {
    try {

      this.isLoading = true;
      this.errorMessage = "";
      this.successMessage = "";
      const { oldPassword, newPassword } = this.changePasswordForm.value;

      const paylaod = {}

      if (this.token) {
        paylaod["password"] = newPassword
        paylaod['token'] = this.token

        const res = await this.apiService.resetPassword(paylaod).toPromise();

        if (!res || !Array.isArray(res) || res.length === 0 || typeof res[0] !== 'string') {
          throw new Error('Password reset unsuccessful. Please try again.')
        } else if (res[0].toLowerCase().includes('invalid')) {
          throw new Error("Invalid reset token.")
        }

        this.successMessage = res.toString();

      } else {
        paylaod["password"] = newPassword
        paylaod['old_password'] = oldPassword
        paylaod['id'] = localStorage.getItem(PersistedValueEnum.userId)
        paylaod['clientID'] = localStorage.getItem("client") ? JSON.parse(localStorage.getItem("client"))?.clientID : null

        const res = await this.apiService.changePasswordApi(paylaod).toPromise();

        if (!res || !Array.isArray(res) || res.length === 0 || typeof res[0] !== 'string') {
          throw new Error('Password reset unsuccessful. Please try again.')
        } else if (res[0].toLowerCase().includes('invalid')) {
          throw new Error("Invalid reset token.")
        } else if (res[0].toLowerCase().includes('not match')) {
          throw new Error("Current Password does not match.")
        }
        this.changePasswordForm.reset();
        this.successMessage = `${res.toString()}. You are requested to please relogin using your updated password.`;

        setTimeout(() => {
          localStorage.removeItem('user');
          localStorage.setItem('userFullName', "");
          localStorage.setItem('userName', "");
          localStorage.setItem('0', "");
          localStorage.removeItem("token");
          localStorage.removeItem("refresh");
          localStorage.removeItem("last_activity");
          // window.location.href = '/#/auth';
          // window.location.reload();
          this.router.navigate(["/auth"])
        }, 5000)

      }

    } catch (error) {
      console.log(error)
      this.errorMessage = error[0] ? error[0] : error ? error?.error?.error : 'An error occurred while resetting your password. Please try again later.';
    } finally {
      this.isLoading = false;
    }
  }


}
