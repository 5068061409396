/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable no-underscore-dangle */
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { DomSanitizer} from '@angular/platform-browser';
import { PersistedValueEnum } from 'src/app/models/persisted-value-enum';
import { Client } from 'src/app/models/client.model';
import { UserComponent } from '../user/user.component';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { ChpwComponent } from '../chpw/chpw.component';
import { AppService } from 'src/app/app.service';
import { UtilityService } from '../../services/utility.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {

  pageTitle = 'Default header name';
  public userFullName: string;
  public userId: string;
  public client: Client;
  @ViewChild('popover') popover;
  isOpen = false;
  logoUrl?: string;
  loaded: boolean;
  routeSub: Subscription;
  buildVersion = 'loading...';

  constructor(
    private _router: Router,
    private _popover: PopoverController,
    private _activatedRoute: ActivatedRoute,
    private _sant: DomSanitizer,
    private _route: ActivatedRoute,
    private appService: AppService,
    private _matDialog: MatDialog,
    private utilityService: UtilityService)
    {
        //console.log('Loading Header...');
        this.routeSub = _route.params.subscribe(val => {
          this._router.events.pipe(
            filter(event => event instanceof NavigationEnd),
          ).subscribe(() => {
            const childRoute = this.getChild(this._activatedRoute);
            childRoute.data.subscribe(data => {
              try{
              this.getHeaderInfo();
              }catch{
               // console.log('header component '+ localStorage.getItem(PersistedValueEnum.client));
              }
            });
          });
        });
      //using the line below  as ngOnInit because ngOnInit not firing after route.navigate
      this.loadVersion();
    }

  public ngOnInit(): void {}

  public ngOnDestroy(): void {
    if (this.routeSub !== null && this.routeSub !== undefined) {
      this.routeSub.unsubscribe();
    }
   }

  public getHeaderInfo(): void {
    this.client = JSON.parse(localStorage.getItem(PersistedValueEnum.client));
    const tempUser = JSON.parse(localStorage.getItem(PersistedValueEnum.user)) || null;
    this.userFullName = localStorage.getItem(PersistedValueEnum.userFullName);
    this.userId = localStorage.getItem(PersistedValueEnum.userId);

    const current = new Date();
    const sessionDate = new Date(tempUser?.token_exp * 1000);

    if( current > sessionDate){
      this.logoff();
    }
   }

  public getChild(activatedRoute: ActivatedRoute) {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    }
    else {
      return activatedRoute;
    }
  }

  public isLoggedin(): boolean{
   // if (this.pageTitle !== 'Auth' && this.userFullName !== undefined){
    if (this.userFullName) {
       return true;
    }
    else {
      return false;
    }
  }

  public currentUserName(): string {
    return this.userFullName;
  }

  public customerName(): string {
    if (this.client === null || this.client === undefined ) {
      this.getHeaderInfo();
    }
    try {
    return this.client.clientName;
    }catch {
      return 'PlaneOps';
    }
  }

  public customerLogo() {
    if (this.client === null || this.client === undefined ) {
      this.getHeaderInfo();
    }
    try{
      return this._sant.bypassSecurityTrustResourceUrl(this.client.logo) as string;
    }
    catch{
      return '../assets/logo/planeOpsLogo.png';
    }
  }

  public logoff(): void {
    this.appService.triggerRemoveMenu();
    this.userFullName = undefined;
    localStorage.removeItem('user');
    localStorage.setItem('userFullName', "");
    localStorage.setItem('userName', "");
    localStorage.setItem('0', "");
    localStorage.removeItem("token");
    localStorage.removeItem("refresh");
    localStorage.removeItem("last_activity");
    localStorage.removeItem('subSelectedTab');
    localStorage.removeItem('assetsActiveTab');
    localStorage.removeItem('activeMenuIndex');
    this.closePopover();
    this._router.navigate(["/auth"])

  }

  public closePopover(): void{
      this._popover.dismiss();
  }

  openPopover(e: Event){
    this.popover.event = e;
    this.isOpen = true;
  }

  goTo(pageUrl: string){
    this._router.navigate(['/'+pageUrl]);
    this.closePopover();
  }

   public openUserSettings(): void {
    this.closePopover();
    this._matDialog.open(UserComponent, {
      data: {users: null, id: this.userId, client: this.client, sService: true}
    });
  }
  public openChangePassword(): void {
    this.closePopover();
    this._matDialog.open(ChpwComponent);
  }

  private loadVersion(): void {
    this.utilityService.getVersion().subscribe(
      version => this.buildVersion = version,
      error => this.buildVersion = 'unknown'
    );
  }

}
